import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import JobRow from "./jobrow";
      


function JobQueue(props){
    return(
        <div>
        <h2>Queue</h2>
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell>Start</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Current status</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                    {
                        props.rows.map(
                            row => {
                                return(
                                    <JobRow addItem={props.addItem} row={row} key={row.id} showPlayer={props.showPlayer} removeChild={props.removeChild} />
                                );
                            }
                        )
                    }
                </TableBody>
            </Table>
            </TableContainer>
            

</div>
    );
}

export default JobQueue;