import React, {  useRef,useEffect, useState } from 'react';

function Playlist(props){


  //let currentSourceIndex=0;
  const savedCallback = useRef();
  const [currentSourceIndex,setCurrentSourceIndex] = useState(0);

  useEffect(() => {
    savedCallback.current=playNext;
  },[currentSourceIndex])

  const selectSource = (i) => {
    console.log("playing " + (i+1) + " from " + props.sources.length)
    //this.props.changeSource(this.state.sources[i]); 
    setCurrentSourceIndex(i);
    //console.log(this.props.sources[i]);
    props.closePlayer();
    props.showPlayer(props.sources[i],() => () => savedCallback.current());
  };

  // const addSource = (src) => {
  //   var newSources = sources;
  //   newSources.push(src);
  //   setSources(newSources);
  // };

  const playNext = () => {
    console.log("next");
    var nextSourceIndex = currentSourceIndex+1;
    console.log(nextSourceIndex);
    if(nextSourceIndex<props.sources.length){
      selectSource(nextSourceIndex);
    }
  };




      return(
        <div class="container">
          <button class="close-btn" onClick={props.closePlaylist}>×</button>
          {
            props.sources.map(
              (source,i) => {
                return <div key={"playlistElement" + i} class={currentSourceIndex==i ? "row playlistActive" : "row"}>
                <div class="col">{source.title}</div>
                <div class="col"><span onClick={() => selectSource(i)}>▶</span></div>
            </div>
              }
            )
          }
    </div>
      );

    
}

export default Playlist;