import React from "react";
import * as api from './api/api';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

function formatTime(timestamp){
    let date = new Date(timestamp * 1000);
    let formatedDate =
        date.getDate() + "." +
        (date.getMonth()+1) + "." +
        date.getFullYear() + " - " +
        date.getHours() + ":" +
        date.getMinutes() + ":" +
        date.getSeconds()
        ;
    return formatedDate;

}


function JobRow(props){


                return(
                    <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell>{formatTime(props.row.ts_start)}</TableCell>
                    <TableCell>{props.row.type}</TableCell>
                    <TableCell>{<a href={props.row.url} target="_blank">{props.row.title}</a>}</TableCell>
                    <TableCell>{api.getStatus(props.row.status)}</TableCell>
                    <TableCell>{props.row.status==api.FINISHED ? <a href={props.row.download_url}>Download here</a> : ""}</TableCell>
                    <TableCell><span onClick={(e) => props.removeChild(props.row.id)} className="btn-remove">x</span></TableCell>
                    <TableCell>{props.row.status==api.FINISHED ? <span onClick={(e) => props.showPlayer(props.row)} >▶</span> : "" }</TableCell>
                    <TableCell>{props.row.status>=api.IN_QUEUE ? <span onClick={() => props.addItem(props.row)}>➕</span> : ""}</TableCell>
                    </TableRow>
                );

}


export default JobRow;