import JobForm from './elements/jobForm';
import axios from 'axios';
import { BrowserRouter as Router , Routes, Route, Navigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import * as api from './elements/api/api';
import './App.css';
import LoginForm from './elements/loginForm';
import { useEffect,useState } from 'react';



function Header(){
  return(
  <div id="header">
    <h1>YT-Downloader</h1>
  </div>
  );
}

function CreateSession(){
  let {uid} = useParams();
  console.log(uid);
  api.createSession(uid,success => {
    if(!success){
      alert("Session was not found. Maybe it is expired. If you use the remember function a new session with empty queue will started.");
    }
    window.location.href="/login";
  });
}

function App() {
  const [loggedIn, setLoggedIn] = useState(null)
  axios.defaults.withCredentials = true;  

  useEffect(() => {
    api.checkLogin(
      (res) => {setLoggedIn(res.logged_in)}
    )
  }, [] );

  return (
    <div className="App">
      <Header />
      <div id="main">
      <Router>
        <Routes>
        <Route path='/login/:uid/' element={<CreateSession/>}></Route>
        <Route path='/login' element={loggedIn ? <Navigate push to="/" /> : <LoginForm />}></Route>
        <Route path='/' element={ !loggedIn ? <Navigate push to="/login" /> : <JobForm />}></Route>
        </Routes>
      </Router>
      </div>
      
    </div>
  );
}

export default App;
