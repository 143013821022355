import React, { useEffect,useRef } from 'react';

function Player(props){

    const audioRef = useRef();
    const videoRef = useRef();

    const closePlayer = () => {
        props.closePlayer();
    }

    useEffect(() => {
        if(props.type=="audio"){
            if(audioRef){
                audioRef.current.load();
                audioRef.current.play();
            }
        }
        else if(props.type=="video"){
            if(videoRef){
                videoRef.current.load();
                videoRef.current.play();
            }
        }
    },[props.source]);

    const callbackAfterMediumEnds = () => {
        //console.log(props);
        props.callbackAfterMediumEnds();
    };

        return(
            <div class="overlay">
            <div class="kasten" onClick={e => e.preventDefault()}>
            <button class="close-btn" onClick={closePlayer}>×</button>
                <h2>{ props.title }</h2>
                {
                    props.type=="audio" ? 
                    <audio ref={audioRef} autoPlay onEnded={callbackAfterMediumEnds} controls>
                        <source src={props.source}></source>
                    </audio> :
                    <video ref={videoRef} autoPlay onEnded={callbackAfterMediumEnds}  style={{width: "auto",maxHeight: "80%",margin:"auto"}} controls>
                        <source src={props.source}></source>
                     </video>
                }
            </div>
        </div>
        );

}

export default Player;