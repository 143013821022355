import React, { useState,useEffect } from 'react';
import useInterval from '../Hooks/useInterval';
import JobQueue from './JobQueue';
import Player from './Player';
import Playlist from './Playlist';
import * as api from './api/api';
import * as cookies from './api/CookieManagement';
import ConfirmationButton from './confirmationButton';
import PatternInput from './PatternInput'
import { Button } from '@mui/material';




function JobForm(props) {
  const pattern = /^[a-zA-Z0-9\-_\.]+@[a-zA-Z0-9\-_\.]+\.[a-z]{2,}$/; //Pattern to validate mail address

      let ticker = null;
      //const [tickerActive,setTickerActive] = useState(false);
      const [mail,setMail] = useState(cookies.getCookie("mail"));
      const [rows,setRows] = useState([]);
      const [url,setUrl] = useState("");
      const [type,setType] = useState("");
      const [sources,setSources] = useState([]);
      const [confirmationButtonEnabled,setConfirmationButtonEnabled] = useState(true);
      const [useMailNotification,setUseMailNotfication] = useState(false);
      const [showPlayerState,setShowPlayerState] = useState(false);
      const [selectedMediaFile,setSelectedMediaFile] = useState({});
      const [callbackAfterMediumEnds,setCallbackAfterMediumEnds] = useState(() => () => {return ;});
      const [showPlaylistState,setShowPlaylistState] = useState(false);
      const urlRef = React.createRef();
  

  const closePlayer = ()=>{
    setShowPlayerState(false);
    document.title = "YT-Downloader";

  };

  const closePlaylist = () => {
    setShowPlaylistState(false);
  }

  const showPlayer = (selectedMediaFile, callbackAfterMediumEnds=()=>()=>{return ;}) => {
    //console.log(selectedMediaFile);
    //console.log(callbackAfterMediumEnds);
    setCallbackAfterMediumEnds(callbackAfterMediumEnds);
    setShowPlayerState(true);
    setSelectedMediaFile(selectedMediaFile);
    document.title = selectedMediaFile.title;
  };

  const showPlaylist = () => {
    setShowPlaylistState(true);
  }

  const removeChild = (id) => {
    let confirmed = window.confirm("Do you realy want remove this job?");
    if(confirmed){
        api.removeJob(id, () => {
          let newRows = rows.filter(row => row.id!=id)
          setRows(newRows);
        });
    }
  };

    const update = (inital=false) => {
      //unsetTicker();
      let l = rows.filter(row => row.status != 3 & row.status!=0).length;
      if(l==0 && !inital)
        return;
      api.getJobs(
          json => {
              let r = [];
              for(var k in json){
                r.push(json[k]);
              }
              setRows([]);
              setRows(r);
          }
      );
      //setTicker();
  }



useEffect(
  () => {
    update(true);
  },[]);

  useInterval(update,5000);

  const addItemToSource = (item) => {
    for(let i=0;i<sources.length;i++){
      if(sources[i].url==item.url & sources[i].type==item.type){
        alert("The playlist already contains this item.");
        return ;
      }
    }
    setSources([...sources,item]);
  };


  const storeMailAddress = (sendMail = useMailNotification) => {
    if(sendMail){
      api.storeMailAddress(mail);
    }
    else{
      api.storeMailAddress("");
    }
  };

  const mailPatternMatched = (mail) => {
    storeMailAddress();
    cookies.setCookie("mail", mail, 28);
  };

   const addJobButtonClicked = () => {
      if(type===""){
        alert("Please select a format");
        return ;
      }

      for(var k in rows){
        if(rows[k].url === url){
          alert("This clip is aleady in your queue!");
          return ;
        }
      }
      let newRow = {ts_start:Date.now() / 1000,ts_last_action:Date.now() / 1000,status:0, title:url, url: url,type:type};
      let r=rows.concat([newRow]);
      let url2 = url;
      setUrl("");
      setRows(r);
      api.addJob(
        url2,
        type,
        (json) => 
        { 
          //console.log("url");
          //console.log(url);
          if(json.title!=null){
            storeMailAddress();
            let newRows=r;
            for(let i=0; i<newRows.length; i++){
              if(newRows[i].url==url2){
                  
                  newRows[i]=json;
                  setRows([]);
                  setRows(newRows);
                  break;
              }

            }
            
          }
          else{
            alert("Invalid URL: " + url2);
          }

        })
      };
  
      return(
        <div>
          {showPlayerState ? <Player type={selectedMediaFile.type} title={selectedMediaFile.title} source={selectedMediaFile.stream_url} closePlayer={closePlayer} callbackAfterMediumEnds={callbackAfterMediumEnds} /> : ""}
            <div id="jobForm">
            <Button onClick={showPlaylist}>Open Playlist</Button>
                <fieldset>
                <legend>URL</legend>
                <input type="text" ref={urlRef} id="url" value={url} onChange={(e) => setUrl(e.target.value)} />
                </fieldset>
                <fieldset>
                <legend>Format</legend>
                Audio 
                <input type="radio" name="type" value="audio" onChange={(e) => setType(e.target.value)} />
                Video 
                <input type="radio" name="type" value="video" onChange={(e) => setType(e.target.value)} />
                </fieldset>
                <ConfirmationButton captionDisabled="In progress..." captionEnabled="Add job to queue" enabled={confirmationButtonEnabled} onClick={addJobButtonClicked.bind(this)} /> 
                <hr />
                <p>
                <fieldset>
                  <legend>
                    E-Mail notification
                  </legend>
                <input type="checkbox" checked={useMailNotification} onClick={() => {setUseMailNotfication(!useMailNotification); storeMailAddress(!useMailNotification); }} />
                <PatternInput value={mail} placeholder="E-Mail" enabled={useMailNotification} pattern={pattern} onMatch={v => mailPatternMatched(v)} />
                </fieldset>
              </p>
              <div>
                <JobQueue addItem={addItemToSource} rows={rows} showPlayer={showPlayer} removeChild={removeChild}/>
              </div>
              {
                showPlaylistState ? <Playlist closePlaylist={closePlaylist} setCallbackAfterMediumEnds={setCallbackAfterMediumEnds} closePlayer={closePlayer} showPlayer={showPlayer} sources={sources} /> : ""
              }
            </div>
          </div>
      );
  
    
}

export default JobForm;