import React, {  useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as api from './api/api';
import ConfirmationButton from './confirmationButton'


function LoginForm(props){
    const navigate = useNavigate();
    const [confirmationButtonEnabled,setConfirmationButtonEnabled] = useState(true);
    const [remember,setRemember] = useState(true);
    const [success,setSuccess] = useState(false);
    const [password,setPassword] = useState("");


    const loginButtonClicked = () => {
      setConfirmationButtonEnabled(false);
      api.login(password, remember, json => {
        if(json.success){
          setSuccess(true);
          //navigate("/");
          window.location = "/";
        }
        else{
          alert("Wrong password!");
          setConfirmationButtonEnabled(true);
          setPassword("");
        }
      });

    };

  
        return(
            <div id="loginForm">
                <fieldset>
                <legend>Password</legend>
                <input type="password" size="20" value={password} onChange={(e) => setPassword(e.target.value)}/><br/>
                Remember me 
                <input type="checkbox" checked={remember} onClick={(e) => setRemember(!remember)} /><br/>
                <ConfirmationButton captionDisabled="In progress..." captionEnabled="Login" enabled={confirmationButtonEnabled} onClick={loginButtonClicked} />
                </fieldset>
            </div>
        );
    
}

export default LoginForm;