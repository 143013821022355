import React, { useState } from "react";

function PatternInput(props){
   
   const [matched,setMatched] = useState(false);
   const [currentValue,setCurrentValue] = useState(props.value);

   const valueChanged = (e) =>{
        setCurrentValue(e.target.value);
        if(props.pattern.test(e.target.value)){
            
            props.onMatch(e.target.value);
            setMatched(true);
        }
    };
    return(
    <input value={currentValue} placeholder={props.placeholder} disabled={!props.enabled} type="text" onChange={e => valueChanged(e)} className={matched ? "pattern-input-matched" : "pattern-input-unmatched"}/>
);

    
}

export default PatternInput;